export const es = {
  translation: {
    selectLanguage: 'Por favor seleccione su idioma',
    scheduleYourRide: '¡Programemos su viaje!',
    importantNote: 'Si hay una emergencia médica, llame al 911 de inmediato',
    ifYouAppointment:
      'Si faltan menos de 24 horas para su cita, programe la cita a través del teléfono ',
    selectHealthInsurance: 'Seleccione su seguro médico',
    selectPurpose: 'Seleccione el propósito de su visita',
    provideContactDetails: 'Por favor proporcione sus datos de contacto',
    provideDropOffTime: 'Proporcione la fecha y hora de su cita o lugar de entrega preferido',
    providePickUpAddress: 'Ingrese su dirección de recogida',
    provideDropOffAddress: 'Por favor ingrese su dirección de destino',
    provideId: 'Proporcione su identificación de miembro',
    provideDetails: 'Proporcione sus datos de miembro',
    appointmentHint:
      'Si faltan menos de 24 horas para su cita, programe la cita a través del teléfono 844-743-4344',
    next: 'Siguiente',
    back: 'Regresar',
    selectOrType: 'Escriba aquí o seleccione uno',
    phoneNumber: 'Número de teléfono',
    landline: 'Teléfono fijo',
    mobile: 'Teléfono móvil',
    email: 'Email',
    additionalConfirmationEmail: 'Additional Confirmation Email Recipient',
    memberId: 'Identificación de miembro',
    firstName: 'Primer nombre',
    secondName: 'Segundo nombre',
    birthDate: 'Fecha de nacimiento',
    start: 'Comienzo',
    date: 'Fecha',
    time: 'Hora',
    timezone: 'Zona horaria',
    invalidmemberId: 'Proporcione una identificación de miembro válida',
    pleaseEnterDate: 'Por favor ingrese una fecha',
    enterValidDate: 'Ingrese una fecha válida',
    pleaseEnterTime: 'Por favor ingrese la hora',
    enterValidNumber: 'Por favor ingrese un número de teléfono válido',
    enterValidEmail: 'Por favor introduzca una dirección de correo electrónico válida',
    pleaseAddPurposeVisit: 'Por favor seleccione el propósito',
    pleaseChooseLocation: 'Por favor elija la ubicación',
    pleaseEnterAdress: 'Por favor ingrese la direccion',
    pleaseEnterDestinationAddress: 'Por favor ingrese su dirección de destino',
    pleaseEnterCity: 'Por favor ingrese la ciudad',
    pleaseEnterState: 'Por favor ingrese el estado',
    pleaseEnterZipCode: 'Por favor ingrese el código postal',
    locateId: 'Busque su identificación de miembro en la tarjeta del seguro',
    yourFirstName: 'Su nombre',
    yourSecondName: 'Tu segundo nombre',
    searchForAddress: 'Buscar dirección',
    inputAddressHere: 'Escriba la dirección aquí',
    addressLine1: 'Dirección Línea 1',
    addressLine2: 'Dirección(cont.)',
    addressLine2Placeholder: 'Apartamento, edificio, unidad, etc.',
    city: 'Ciudad',
    state: 'Estado',
    zip: 'Código postal',
    textHome: 'Nombre de la dirección',
    placeholderHome: 'Ej. Casa',
    ex: 'ej.',
    ext: 'Ext.',
    cancel: 'Cancelar',
    optional: ' (Opcional)',
    primaryCompanionName: 'Nombre del compañero principal',
    relationship: 'Relación',
    contactNumber: 'Número de contacto',
    selectTripType: 'Solo Ida o Viaje Redonde',
    requireAssistance: 'Requiere asistencia?',
    deviceEquipment: 'Dispositivos y equipos',
    roundTrip: 'Viaje Redondo ',
    roundTripPickUpTime: '¿A qué hora le gustaría que lo recogieran de su cita?',
    oneWay: 'Solo Ida',
    willCall: 'llamaré',
    willCallHint1: `Elija "llamaré" si desea llamar cuando esté listo.`,
    willCallHint2: `Nota: El tiempo de espera depende de la disponibilidad del proveedor`,
    none: 'Ninguno',
    one: 'Uno',
    two: 'Dos',
    verifyTripDetails: 'Verificar detalles del viaje',
    submit: 'Entregar',
    healthInsuarance: 'Seguro de salud',
    insuranceId: 'Identificación de miembro',
    companions: 'Compañero',
    name: `Nombre`,
    member: 'Miembro',
    memberInfo: 'Información de miembro',
    assistance: 'Asistencia',
    cancelWarning: 'Todos los datos ingresados se perderán si continúa ',
    suggestedTime: 'Hora de recogida sugerida',
    unavailablePlan: `Desafortunadamente, CareCar actualmente no funciona con su plan de seguro médico. Por favor, contactar a su compañía de seguros de salud.`,
    oneWayTrip: 'Solo Ida',
    copy: 'Copiar',
    print: 'Imprimir',
    pleaseSelectOne: 'Por favor, seleccione uno',
    fieldCantBeEmpty: 'Este campo no puede estar vacío',
    firstTrip: 'Primer viaje',
    returnTrip: 'Viaje de regreso',
    pickup: 'Recodija',
    dropoff: 'Destino',
    youChooseWillCall: `Elegiste 'Llamaré`,
    noteWaitingTime: `Nota: El tiempo de espera depende de la disponibilidad del proveedor`,
    basedOnADistanceOf: 'Basado en una distancia de',
    withATravelTimeOf: 'Con un tiempo de viaje de',
    forAnAppointmentOf: 'Para un horario de cita de',
    miles: 'millas',
    minutes: 'minutos',
    returnPickupTime: 'Hora de recogida de regreso que seleccionó',
    placeholderHospital: 'Ej. Hospital ',
    tripDetails: 'Detalles del viaje',
    enterPickupTime: 'Este campo no puede estar vacío',
    enterValidData2:
      'Por favor ingrese un valor válido. Este campo está incompleto o tiene una fecha no válida',
    successText: 'Su solicitud ha sido enviada con éxito.',
    succesText2: 'Guarde esta información para sus registros.',
    notes: 'Notas',
    notesPlaceholder:
      'El código de la puerta es 1234 / Edificio n.° 2 / Necesitaré ayuda para descargar mi andador en el automóvil / Nos vemos justo enfrente de la dirección que he proporcionado, al lado del buzón / Tengo un sombrero azul / Además, está en el lado norte de la calle',
    enterManualAddress: 'Haga clic aquí para ingresar manualmente la dirección',
    companionAge: 'Los acompañantes deben ser mayores de 18 años',
    other: 'Otro',
    note2Min: 'Nota: Tarda aproximadamente 2 minutos',
    pleaseSpecify: 'Por favor especifica',
    placeholderSpecify: 'Gimnasio, Supermercado, etc.',
    notesTip: 'Las notas nos ayudan a brindar el más alto nivel de servicio posible',
    referenceNumber: 'Su número de referencia',
    phoneNumberTip:
      'Si está programando en nombre de otra persona, ingrese el mejor número para comunicarse con el miembro el día de la cita',
    enterLastFiveDigits: 'Por favor ingrese los últimos 5 dígitos',
  },
};
export default es;
