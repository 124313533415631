/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  DatePicker,
  Input,
  Select,
  SelectInput,
  TimezoneOffset,
} from '../../../../components';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../types/stores';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { dateFormats, defaultTimezone, timezoneOptions } from '../../../../helpers/constants';
import { bindActionCreators } from 'redux';
import { appActions } from '../../../../store/app';
import moment from 'moment';
import tipIcon from '../../../../assets/icons/tip.svg';
import ReactTooltip from 'react-tooltip';
import commonStyles from '../../../../styles/common.module.scss';
import { metersToMiles, secondsToMinutes } from '../../../../utils';
import { CircularProgress, MenuItem } from '@mui/material';
import { Timezone } from '../../../../types';
import { getDateTimeZoneOffset, getIsoDateTime, isDST } from '../../../../helpers/datetime';

interface Props {
  onClickBack: () => void;
  onClickNext: () => void;
  setValue: (value: string | Date | Timezone, fieldName: string) => void;
}

const AppoitmentTimeForm: React.FC<Props> = ({ onClickBack, onClickNext, setValue }) => {
  const [isError, setIsError] = useState(false);
  const matches = useMediaQuery('(max-width:576px)');
  const { t } = useTranslation();
  const {
    dropOffDate,
    timezone,
    dropOffTime,
    dropOffLocation,
    pickUpLocation,
    tripEstimation,
    estimationLoading,
  } = useSelector((state: AppState) => state);
  const { estimateTrip } = bindActionCreators(appActions, useDispatch());

  const onSubmit = (date: string, timezone: Timezone, time: string) => {
    if (isValid()) {
      setValue(date, 'dropOffDate');
      setValue(timezone, 'timezone');
      setValue(time, 'dropOffTime');
      onClickNext();
    } else setIsError(true);
  };

  const { handleSubmit, setFieldValue, errors, touched, values } = useFormik({
    validationSchema: yup.object({
      dropOffDate: yup.date().required('Please enter date').typeError('Enter a valid date'),
      dropOffTime: yup.string().required('Please enter time'),
    }),
    initialValues: {
      dropOffDate: dropOffDate || '',
      timezone: timezone,
      dropOffTime: dropOffTime || '10:00',
    },
    onSubmit: (): void => onSubmit(values.dropOffDate, values.timezone, values.dropOffTime),
  });

  const isValid = () => {
    const { dropOffDate, dropOffTime } = values;
    const currentDate = new Date().getTime();
    const date = new Date(`${dropOffDate} ${dropOffTime}`).getTime();

    return (date - currentDate) / (1000 * 3600 * 24) > 1;
  };

  const estimatePickupTime = tripEstimation?.pickup_time ?? '';
  const estimateUtcOffset = getDateTimeZoneOffset(new Date(estimatePickupTime), values.timezone);

  const renderHint = () => isError && <p className={styles.errorText}>{t('appointmentHint')}</p>;

  const renderTripTipContent = () => {
    return (
      <div>
        <div className={styles.row}>
          <p className={styles.tipContentText}>{t('basedOnADistanceOf')}&nbsp; </p>
          <p className={styles.tipContentTextBold}>
            {metersToMiles(tripEstimation?.distance)} {t('miles')}
          </p>
        </div>
        <div className={styles.row}>
          <p className={styles.tipContentText}>{t('withATravelTimeOf')}&nbsp; </p>
          <p className={styles.tipContentTextBold}>
            {secondsToMinutes(tripEstimation?.estimate)} {t('minutes')}
          </p>
        </div>
        <div className={styles.row}>
          <p className={styles.tipContentText}>{t('forAnAppointmentOf')}&nbsp; </p>
          <p className={styles.tipContentTextBold}>
            {moment(new Date(`${values.dropOffDate} ${values.dropOffTime}`)).format('hh:mm A')}{' '}
            {timezone.code}
          </p>
        </div>
      </div>
    );
  };

  const renderLoader = () =>
    estimationLoading ? <CircularProgress color="primary" size={20} /> : renderSuggestedTime();

  const renderSuggestedTime = () => {
    return (
      tripEstimation &&
      !isError && (
        <div className={styles.suggestedTimeContainer}>
          <p className={styles.suggesteTimeText}>
            {t('suggestedTime')}: <br />
            <span data-testid="suggested-trip-date">
              {moment(estimatePickupTime)
                .utcOffset(estimateUtcOffset)
                .format(dateFormats.suggestedTripDate)}
            </span>
            {', '}
            <span data-testid="suggested-trip-time">
              {moment(estimatePickupTime)
                .utcOffset(estimateUtcOffset)
                .format(dateFormats.suggestedTripTime)}
            </span>{' '}
            <span data-testid="suggested-trip-timezone-code">{timezone.code}</span>
          </p>
          <ReactTooltip className={styles.tooltip} getContent={renderTripTipContent} />
          <img
            src={tipIcon}
            data-tip="tooltip"
            data-type="light"
            data-class={styles.tipContainer}
          />
        </div>
      )
    );
  };

  const tryEstimateTrip = (date: string, timezone: Timezone, time: string) => {
    if (date.length === 10 && time.length === 5) {
      const dateStr = `${date} ${time}`;
      const browserDate = new Date(dateStr);
      const isoDateStr = getIsoDateTime(browserDate, timezone);
      const isoDate = new Date(isoDateStr);
      setValue(isoDate, 'appointmentTime');
      estimateTrip(isoDateStr, pickUpLocation, dropOffLocation);
    }
  };

  const onChangeTime = (value: string) => {
    setFieldValue('dropOffTime', value).then(() => {
      if (value.length === 5) {
        tryEstimateTrip(values.dropOffDate, values.timezone, value);
      }
    });
  };

  const onChangeTimezone = (timezone: Timezone) => {
    setFieldValue('timezone', timezone);
    setValue(timezone, 'timezone');
    tryEstimateTrip(values.dropOffDate, timezone, values.dropOffTime);
  };

  const onChangeDate = (fieldName: string, value: string) => {
    setIsError(false);
    setFieldValue(fieldName, value).then(() => {
      if (value.length === 10) {
        tryEstimateTrip(value, values.timezone, values.dropOffTime);
      }
    });
  };

  const renderTimezoneMenu = (value: Timezone, id: number) => (
    <MenuItem key={id} value={value as any}>
      {`${value.code} (${value.name})`}
    </MenuItem>
  );

  return (
    <div className={styles.wrap}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={matches ? 2 : 4} sx={{ padding: matches ? `2vh 4vw 4vh` : 0 }}>
          <Grid item xs={12}>
            {' '}
            <p className={commonStyles.boldText}>{t('provideDropOffTime')}</p>{' '}
          </Grid>
          <Grid item xs={matches ? 12 : 12}>
            <DatePicker
              useMinDate
              text={t('date')}
              id="dropOffDate"
              name="dropOffDate"
              onChange={onChangeDate}
              onBlur={tryEstimateTrip}
              value={values.dropOffDate}
              error={!!touched.dropOffDate && Boolean(errors.dropOffDate)}
              helperText={touched.dropOffDate && errors.dropOffDate}
              placeholder={dateFormats.appoitmentDatePlaceholder}
            />
          </Grid>
          <Grid item xs={matches ? 12 : 6}>
            <Input
              id="dropOffTime"
              name="dropOffTime"
              text={t('time')}
              type="time"
              variant="outlined"
              onBlur={tryEstimateTrip}
              value={values.dropOffTime}
              InputLabelProps={{
                shrink: true,
              }}
              select={false}
              sx={{ width: '100%' }}
              onChange={({ target }: React.ChangeEvent<any>) => onChangeTime(target.value)}
              helperText={touched.dropOffTime && errors.dropOffTime}
              error={!!touched.dropOffTime && Boolean(errors.dropOffTime)}
            />
          </Grid>
          <Grid item xs={matches ? 12 : 6}>
            <SelectInput
              id="timezone"
              text={t('timezone')}
              onChange={onChangeTimezone}
              value={values.timezone.code}
              valueArray={timezoneOptions}
              renderCustomMenu={renderTimezoneMenu}
            />
          </Grid>
          <Grid item xs={12}>
            {renderHint()}
          </Grid>
          <Grid item display="flex" justifyContent="center" xs={12} sx={{ paddingTop: 0 }}>
            {renderLoader()}
          </Grid>
        </Grid>
        <div className={commonStyles.wrapButton}>
          <Button label={t('back')} outlined onClick={onClickBack} />
          <Button label={t('next')} />
        </div>
      </form>
    </div>
  );
};

export default AppoitmentTimeForm;
